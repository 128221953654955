<template>
  <div class="feed-view">

  </div>
</template>

<script>
export default {
  name: 'FeedView',

  beforeMount() {
    this.$store.commit('meta/setHeader', {
      defaultHeaderDisabled: false,
      pageTitle: 'Feed',
      bgColor: null,
      fontColor: null,
      tagline: null,
      iconOne: 'mdi-newspaper-variant',
      iconTwo: 'FD'
    })
  }
}
</script>

<style lang="scss">

</style>